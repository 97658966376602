import { Carousel } from 'bootstrap';

export default class Home {
    private carouselInstance: Carousel | null;

    constructor() {
        this.carouselInstance = null;
        this.init();
    }

    init() {
        this.initCarouselPlayer();
        Home.initQuickAccessWatcher();
        Home.initStartFirstVideo();
    }

    initCarouselPlayer() {
        const videos = document.querySelectorAll('.carousel-video');
        if (videos) {
            this.initCarouselInstance();
            videos.forEach((video:HTMLVideoElement) => {
                this.initVideoListeners(video);
            });
            Home.initPlayButton();
        }
    }

    initCarouselInstance() {
        if (this.carouselInstance === null) {
            const carouselElement = document.getElementById('carouselVideo') as HTMLDivElement;
            this.carouselInstance = window.bootstrap.Carousel.getInstance(carouselElement);
        }
    }

    initVideoListeners(video: HTMLVideoElement) {
        video.addEventListener('play', () => {
            this.initCarouselInstance();
            this.carouselInstance.pause();
        });
        video.addEventListener('ended', () => {
            video.currentTime = 0;
        });
        video.addEventListener('stop pause ended', () => {
            this.initCarouselInstance();
            this.carouselInstance.cycle();
        });
    }

    static initStartFirstVideo() {
        if (document.querySelector('#carouselVideo').getAttribute('data-pause') === 'true') {
            window.addEventListener('load', () => {
                Home.startFirstVideo();
            });
        }
    }

    static startFirstVideo() {
        const playButton = document.querySelector('.play-button') as HTMLButtonElement;
        playButton.click();
    }

    static initPlayButton() {
        document.querySelectorAll('.play-button').forEach((playBtn) => {
            playBtn.addEventListener('click', () => {
                const videoElement = playBtn.parentElement.querySelector('.carousel-video') as HTMLVideoElement;
                const playSvg = playBtn.querySelector('svg.play');
                const pauseSvg = playBtn.querySelector('svg.pause');
                if (videoElement.paused) {
                    videoElement.play();
                    playSvg.classList.remove('d-none');
                    pauseSvg.classList.add('d-none');
                } else if (videoElement.ended) {
                    videoElement.load();
                } else {
                    videoElement.pause();
                    playSvg.classList.add('d-none');
                    pauseSvg.classList.remove('d-none');
                }
            });
        });
    }

    static initQuickAccessWatcher() {
        const quickAccess = document.querySelector('#quick-access');
        const quickAccessHeight = (quickAccess.firstElementChild as HTMLElement).offsetHeight;
        const quickAccessTranslate = (quickAccessHeight + 24) * 2; // PI. Je ne sais pas pq il faut mettre un * 2
        // on rajoute la hauter du transform translate du btn
        const quickAccessBounding = quickAccess.getBoundingClientRect();
        const quickAccessOffset = {
            top: quickAccessBounding.top + window.scrollY,
            left: quickAccessBounding.left + window.scrollX,
        };
        window.addEventListener('scroll', () => {
            document.querySelectorAll('.home-content > *:not(#quick-access)').forEach((element: HTMLElement) => {
                const elementBounding = element.getBoundingClientRect();
                const elementOffset = {
                    top: elementBounding.top + window.scrollY,
                    left: elementBounding.left + window.scrollX,
                };
                const elementCurrentPosition = elementOffset.top - window.scrollY;
                if (elementCurrentPosition + quickAccessTranslate < quickAccessOffset.top && elementCurrentPosition + quickAccessTranslate + element.offsetHeight > 0) {
                    quickAccess.classList.remove('reverse');
                    if (element.classList.contains('bg-primary')) {
                        quickAccess.classList.add('reverse');
                    }
                }
            });
        });
    }
}

// On document ready
document.addEventListener('DOMContentLoaded', () => {
    (() => new Home())();
});
